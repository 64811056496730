import { IS_APP, IS_DEV } from "@/__main__/constants.mjs";
import { readData } from "@/__main__/get-data.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";
import { initABFeature } from "@/util/use-ab-feature.mjs";

// figure out where to redirect domain...
//
// NOTE: this should probably be temporary. the problem with this approach
// is that it has to load the whole app first. a better approach would be
// to create a separate entry point just to read the country code and load
// that first.
(async () => {
  if (IS_DEV || !IS_APP || isRouteOverlay()) return;
  const res = await readData(["search", "regionData"]);
  const countryCode = res?.["ISO 3166-1 alpha-2"];

  if (countryCode === "US") {
    // get some variation but still being consistent
    // const uniqueId = new Date().toISOString().slice(0, 13).replace("T", "-");
    const uniqueId = new Date().toISOString().slice(0, 10);
    const useProbuilds = await initABFeature(
      ["probuilds-desktop", uniqueId],
      3,
    );
    const blitzHost = "blitz.gg";
    const probuildsHost = "probuilds.net";
    const hostname = useProbuilds ? probuildsHost : blitzHost;
    const version = useProbuilds ? "134.0.0.0" : "126.0.6478.127";
    if (location.hostname !== hostname) {
      const userAgent = navigator.userAgent.replace(
        /Chrome\/\d+\.\d+\.\d+\.\d+/,
        `Chrome/${version}`,
      );
      await blitzMessage(EVENTS.APP_SET_USERAGENT, userAgent);
      // eslint-disable-next-line no-console
      console.log(`Redirecting to ${hostname}`);
      const u = new URL(location);
      u.port = "";
      u.hostname = hostname;
      u.protocol = "https:";
      location = u;
    }
  }
})();
